import React, {Component} from 'react';
import UserWidget from './UserWidget';

import constants from './constants';
import humanTimeSince from './HumanTime'


class PostPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postid: window.location.href.split('/').slice(-1)[0],
      post: null,
      images: [],
      receivedData: false,
    };
  }

  componentDidMount() {
    let data = new FormData();
      data.append('function', 'getPost');
      data.append('id', this.state.postid);
    fetch(constants.API_URL, {
      method: 'POST',
      body: data,
    })
    .then(response => response.json())
    .then(received => {
      this.setState({
        post: received[0],
        images: received[1],
        receivedData: true,
      });
    });
  }

  assembleTextPost() {
    var acc = [];
    var parts = this.state.post.text.split("||");
    console.log(parts);
    for (var p = 0; p < parts.length; p++) {
      if (p % 2 == 1) {
        let index = parseInt(parts[p]);
        if (!isNaN(index) && index < this.state.images.length) {
          acc.push(
              <div className="postpage_image_wrapper">
                <img src={constants.IMG_URL + this.state.images[index]} className="postpage_image"/>
              </div>
          );
        }
      } else {
        acc.push(
          <div className="postpage_text">
            {parts[p]}
          </div>
        );
      }
    }
    return acc;
  }

  assembleCollectionPost() {
    var acc = [];
    for (var i of this.state.images) {
      acc.push(
        <div className="postpage_image_wrapper">
          <img src={constants.IMG_URL + i} className="postpage_ic_image"/>
        </div>
      );
    }
    acc.push(
      <div className="postpage_text">
        {this.state.post.text}
      </div>
    );
    return acc;
  }

  assembleComicPost() {

  }

  renderSubtitle() {
    if (this.state.post.subtitle) {
      return <div className="postpage_subtitle">{this.state.post.subtitle}</div>
    }
  }

  renderPost() {
    switch (this.state.post.type) {
      case 0: // Text post
        return (
          <div style={{display: "flex", paddingTop: "50px"}}>
            <div className="postpage_contents">
              <div className="postpage_title">
                {this.state.post.title}
              </div>
              {this.renderSubtitle()}
              <UserWidget username={this.state.post.username} /><div className="postpage_time"> posted {humanTimeSince(this.state.post.time)}</div>
              {this.assembleTextPost()}
            </div>
          </div>
        );
      break;

      case 1: // Image collection post
        return (
          <div style={{display: "flex", paddingTop: "50px"}}>
            <div className="postpage_contents">
              <div className="postpage_title">
                {this.state.post.title}
              </div>
              {this.renderSubtitle()}
              <UserWidget username={this.state.post.username} /><div className="postpage_time"> posted {humanTimeSince(this.state.post.time)}</div>
              {this.assembleCollectionPost()}
            </div>
          </div>
        );
      break;

      case 2: // Webcomic post

      break;
    }
  }

  renderMissing() {
    return (
      <div className="page_wrapper">
        <div className="page_centered">
          <b>The post you were looking for couldn't be found!</b><br />
          It may have been deleted or unlisted by its creator.
        </div>
      </div>
    );
  }

  renderLoading() {
    return <div></div>;
  }

  render() {
    if (this.state.receivedData) {
      if (this.state.post !== null) {
        return this.renderPost();
      } else {
        return this.renderMissing();
      }
    } else {
      return this.renderLoading();
    }
  }
}


export default PostPage;
