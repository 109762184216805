import React, {Component} from 'react';
import {Link} from "react-router-dom";

import constants from './constants';


class UserWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      receivedData: false,
    }
  }

  componentDidMount() {
    let data = new FormData();
      data.append('function', 'userwidget');
      data.append('username', this.props.username);
    fetch(constants.API_URL, {
      method: 'POST',
      body: data,
    })
    .then(response => response.json())
    .then(received => {
      this.setState({
        data: received,
        receivedData: true,
      });
    });
  }

  render() {
    if (this.state.receivedData) {
      return (
        // Data fields: name, icon, alias, color, numPosts, numSubscribers
        <Link to={"/u/"+this.props.username}>
          <div className="userwidget">
            <img src={constants.IMG_URL + this.state.data.icon} id="usericon" />
            <div className="userwidget_name">
              {this.props.username}
            </div>
          </div>
        </Link>
      );
    } else {
      return (
        <Link to={"/u/"+this.props.username}>
          <div className="userwidget">
            <div className="userwidget_name">
              {this.props.username}
            </div>
          </div>
        </Link>
      );
    }
  }
}


export default UserWidget;
