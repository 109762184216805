import React from 'react';


const UserBlock = (props) => {
  return (
    <div className="user_block">

    </div>
  );
}


export default UserBlock;
