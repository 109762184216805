import React, {Component} from 'react';


class FlexibleLoginForm extends Component {
  render() {
    return (
      <div></div>
    );
  }
}


export default FlexibleLoginForm;
